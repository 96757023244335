import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Quote from "./Quote";
import TechCard from "./TechCard";


const TechStack = () => {
  return (
    <>
      <p className="mt-20 uppercase text-green-500 font-semibold">
        Overarching goal
      </p>

      <div className="grid grid-cols-1 xl:grid-cols-12 mb-8">
        <div className="col-span-1 lg:col-span-6">
          <h2 className="text-3xl font-extrabold mb-4 ">
            Engineering Business Value
          </h2>
          <p className="text-gray-500">
            I&apos;m a hands-on engineer and I am convinced that the purpose of
            software is to create business value by increasing revenue, by
            decreasing cost and by providing the customer with the desired
            functionality.
          </p>
          <Quote>
            <div>
              <p className="text-gray-700 font-medium text-lg">
                Software is a tool that executes on the business model.
              </p>
              <p className="text-gray-700 font-medium text-lg mt-2 sm:mt-0">
                It is not an end in itself - but a means to create business
                value.
              </p>
            </div>
          </Quote>
          <h3 className="font-bold text-2xl mt-8 mb-2">
            Tools I use to get the job done
          </h3>
          <p className="text-gray-500 mb-4">
            In my opionion there is no such thing as the best programming
            language or technology.
          </p>
          <p className="text-gray-500 mb-2">
            There are only tools that work very well for specific tasks.
          </p>
          <p className="text-gray-500 mb-2">
            I am convinced that it is the job of the engineering team to choose
            the tech stack accordingly while also taking into account
            nonfunctional requirements such as time to market, the team&apos;s
            experience and how easy it is to find experienced engineers.
          </p>
        </div>
        <div className="hidden lg:self-center xl:block lg:col-span-6 lg:w-full xl:self-end">
          <StaticImage src="../images/engineering-business-value.png" />
        </div>
      </div>

      <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:gap-12">
        <TechCard
          heading="Spring / Spring Boot with Java"
          tags={[
            "spring",
            "spring-boot",
            "moquito",
            "hibernate",
            "liquibase",
            "webflux",
          ]}
        >
          <p className="text-gray-500">
            I’m a huge proponent for Spring and Spring Boot with Java for the
            enterprise - especially in microservice-based systems.
          </p>
        </TechCard>
        <TechCard
          heading="Node"
          tags={[
            "express",
            "javascript",
            "express-session",
            "mocha",
            "awilix",
            "typescript",
            "yup",
            "knex",
            "supertest",
          ]}
        >
          <p className="text-gray-500">
            I use Node as a backend for anything with fast time-to-market
            expectations, real-time requirements, heavy IO or when only a small
            budget for hosting is available
          </p>
        </TechCard>
        <TechCard
          heading="React"
          tags={[
            "react-hooks",
            "redux",
            "react-router",
            "javascript",
            "gatsby",
            "jest",
            "typescript",
            "enzyme",
          ]}
        >
          <p className="text-gray-500">
            Due to its rich ecosystem, React is my tool of choice to build out
            user interfaces. For static sites (also for this website), I tend to
            use Gatsby.
          </p>
        </TechCard>
      </div>
    </>
  );
};

export default TechStack;
