import React from "react";
import LinkButton from "./LinkButton";
import PortfolioProjectCard from "./PortfolioProjectCard";
import Quote from "./Quote";
import { StaticImage } from "gatsby-plugin-image";


const TalkIsCheap = () => {
  return (
    <>
      <p className="mt-24 uppercase text-green-500 font-semibold">
        Getting real
      </p>
      <h2 className="text-3xl font-extrabold">
        Talk is cheap, show me the code
      </h2>
      <div className="grid lg:grid-cols-12 mb-8 gap-8">
        <div className="lg:col-span-8">
          <Quote>
            <div>
              <p className="text-gray-700 text-lg font-medium">
                Executing is always harder than strategizing
              </p>
              <p className="text-gray-700 text-lg font-medium mt-2 sm:mt-0">
                because executing means acting end-to-end.
              </p>
            </div>
          </Quote>
          <p className="text-gray-500 mt-4">
            Below you’ll find a couple of featured open source projects I did
            with different technologies.
          </p>
          <p className="text-gray-500 mt-1">
            Please bear in mind that most of my Spring / Spring Boot work was
            done as part of enterprise software development and is therefore
            closed source.
          </p>
        </div>
        <div className="hidden lg:block lg:col-span-4 w-full self-end">
          <StaticImage src="../images/programming.png" />
        </div>
      </div>

      <h3 className="font-bold text-2xl mt-8 mb-2">Frontend</h3>
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-12">
        <PortfolioProjectCard
          tags={[
            "react",
            "redux",
            "redux-saga",
            "jest",
            "enzyme",
            "create-react-app",
            "react-router",
            "redux-reselect",
          ]}
          heading="Build Youtube in React"
          videoUrl="https://www.youtube.com/embed/E7wJTI-1dvQ"
          codeUrl="https://github.com/jangbl/youtube-react"
          demoUrl="https://quizzical-knuth-6a4cf1.netlify.app"
        >
          <p className="text-gray-500">
            Built a Youtube clone in React and made a 45-part tutorial series
            about it.
          </p>
        </PortfolioProjectCard>
        <PortfolioProjectCard
          tags={[
            "react",
            "react-hooks",
            "yelp-fusion-api",
            "bulma",
            "react-router",
            "javascript",
            "css-modules",
          ]}
          heading="Build Yelp in React"
          videoUrl="https://www.youtube.com/embed/8asNYp7koRg"
          codeUrl="https://github.com/jangbl/yelp-frontend"
          demoUrl="https://frosty-haibt-e7cdc6.netlify.app"
        >
          <p className="text-gray-500">
            Built a Yelp clone with React, React hooks and the Yelp Fusion REST
            API.
          </p>
        </PortfolioProjectCard>
      </div>
      <h3 className="font-bold text-2xl mt-8 mb-2">Backend</h3>
      <div className="grid md:grid-cols-2 md:gap-8 xl:grid-cols-3 gap-12 ">
        <PortfolioProjectCard
          tags={[
            "node",
            "javascript",
            "express",
            "express-session",
            "redis",
            "cookie",
          ]}
          heading="Sessions in express.js"
          videoUrl="https://www.youtube.com/embed/bvQah0k5-eA"
          codeUrl="https://github.com/jangbl/express-session-with-redis"
        >
          <p>
            Built a session and cookie-based authentication system with Node and
            Redis and made 10-part tutorial series on Youtube about it.
          </p>
        </PortfolioProjectCard>
        <PortfolioProjectCard
          tags={[
            "node",
            "javascript",
            "docker",
            "docker-compose",
            "mocha",
            "postgresql",
          ]}
          heading="Sessions in express.js"
          videoUrl="https://www.youtube.com/embed/SzbdtTAZvvc"
          codeUrl="https://github.com/jangbl/test-node-with-docker"
        >
          <p>
            Shows how to test a Node CRUD application with a real database by
            spinning up a Postgres database in a Docker container when npm test
            is run.
          </p>
        </PortfolioProjectCard>
        <PortfolioProjectCard
          tags={[
            "node",
            "javascript",
            "docker",
            "docker-compose",
            "mocha",
            "postgresql",
          ]}
          heading="Dependency Injection in Node"
          videoUrl="https://www.youtube.com/embed/TxxdqfhMUnI"
          codeUrl="https://github.com/jangbl/node-dependency-injection"
        >
          <p>
            Shows how to use dependency injection with awilix in a Node project
            to make the code more modular and easy to test. The test suite is
            included.
          </p>
        </PortfolioProjectCard>
      </div>
      <h3 className="font-bold text-2xl mt-8">More projects</h3>
      <p className="text-gray-500 my-2">
        To see the code for all open source projects I did, please visit my
        Github account.
      </p>
      <LinkButton
        to="https://github.com/jangbl"
        text="All Projects on Github"
      />
    </>
  );
};

export default TalkIsCheap;
