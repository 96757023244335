import React from "react";
import VideoBadge from "./VideoBadge";
import PortfolioProjectButtons from "./PortfolioProjectButtons";

const PortfolioProjectCard = (props) => {
  const tags = props.tags.map((tag, index) => (
    <span key={index} className="text-blue-500 font-medium mr-2">
      #{tag}
    </span>
  ));

  return (
    <div className="rounded-xl shadow-2xl p-8 mb-2">
      <VideoBadge />
      <h4 className="text-xl font-semibold">{props.heading}</h4>
      <div className="flex flex-wrap mb-2">{tags}</div>
      {props.children}
      <div className="video-container my-2">
        <div className="video">
          <iframe
            src={props.videoUrl}
            frameBorder="0"
            className="video-player"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
          />
        </div>
      </div>
      <PortfolioProjectButtons
        demoUrl={props.demoUrl}
        codeUrl={props.codeUrl}
      />
    </div>
  );
};

export default PortfolioProjectCard;
