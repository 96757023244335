import React from "react";

const VideoBadge = () => {
  return (
    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
      Video
    </span>
  );
};

export default VideoBadge;
