import React from "react";
import Layout from "../components/Layout";
import SocialLinks from "../components/SocialLinks";
import Quote from "../components/Quote";
import LinkButton from "../components/LinkButton";
import TechStack from "../components/TechStack";
import TalkIsCheap from "../components/TalkIsCheap";
import Testimonials from "../components/Testimonials";
import { StaticImage } from "gatsby-plugin-image";
import { SEO } from "../components/SEO";

const About = ({ data }) => {
  return (
    <Layout>
      <section className="grid grid-cols-1 lg:grid-cols-10 lg:gap-8 lg:pt-16">
        <div className="col-span-5 self-center">
          <h1 className="mb-2">
            <span className="block font-extrabold text-5xl text-gray-900">
              I&apos;m Jan
            </span>
            <span className="block font-bold text-3xl text-blue-500">
              Engineering Lead
            </span>
          </h1>
          <SocialLinks />
          <p className="text-xl text-gray-500 mt-8 mb-2">
            I’m a hands-on tech geek who is connecting the dots between people,
            software, marketing &amp; sales to
            <span className="text-blue-500"> engineer business value</span>.
          </p>

          <Quote>
            <p className="text-gray-700 font-medium text-lg">
              Customers don&apos;t care what technology you use as long as it
              fixes their problem. People want to buy an outcome and oftentimes
              software is the cheapest and most efficient way to get what the
              customer wants.
            </p>
          </Quote>
          <p className="my-4 text-gray-500 text-lg">
            I always strive to develop and apply end-to-end skills which is why
            I am also interested in non-technical domains like marketing and
            sales.
          </p>
          <LinkButton text="Contact me" to="mailto:jan@jangoebel.com" />
          {/*<LinkButton
            text="Resume"
            to="https://drive.google.com/file/d/1jf6vlN9YiJ1LFe_GG6y7IFIFoSIQZSED/view"
            empty={true}
          />*/}
        </div>
        <StaticImage
        src="../images/jan-goebel.jpg"
          className="lg:col-span-5 lg:justify-self-end self-center w-full max-w-lg rounded-lg"
        />
      </section>
      <TechStack />
      <TalkIsCheap />
      <Testimonials />
    </Layout>
  );
};

export const Head = () => <SEO
  title="About Jan Goebel"
  pathname="/about"
  description="Jan Goebel is an Engineering Lead who writes about connecting the dots between between people,
  software, marketing and sales to engineer business value."
/>


export default About;
