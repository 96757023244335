import React from "react";
import Testimonial from "./Testimonial";

const Testimonials = () => {
  return (
    <>
      <p className="mt-20 uppercase text-green-500 font-semibold">Feedback</p>
      <h2 className="text-3xl font-extrabold mb-8">
        Praise I received on the internet
      </h2>
      <div className="flex flex-wrap">
        <Testimonial
          name="aldy colares"
          text="I was looking for this for weeks. Thanks."
          url="https://www.youtube.com/watch?v=TxxdqfhMUnI"
          colorClass="bg-red-500"
        />
        <Testimonial
          name="Muktesh Gautam"
          text="Very nice setup! Learning a lot from this series, thanks a ton!"
          url="https://www.youtube.com/watch?v=AlE021bw3Fc"
          colorClass="bg-yellow-600"
        />
        <Testimonial
          name="Abdelhamid Ismail"
          text="That’s good, thanks."
          url="https://www.youtube.com/watch?v=bvQah0k5-eA"
          colorClass="bg-green-500"
        />
        <Testimonial
          name="aaa bbb"
          text="Man you do understand programming and learning process."
          url="https://www.youtube.com/watch?v=qzKRzwZzc6o"
          colorClass="bg-indigo-700"
        />
        <Testimonial
          name="subhanga upadhaya"
          text="Loving the content man!"
          url="https://www.youtube.com/watch?v=r5QTHjmYA04"
          colorClass="bg-red-500"
        />
        <Testimonial
          name="shannscott2000"
          text="Thanks so much for these. They’re great!"
          url="https://www.youtube.com/watch?v=TxxdqfhMUnI"
          colorClass="bg-blue-500"
        />
        <Testimonial
          name="aware penguin"
          text="Subbed. Thanks for your tutorials"
          url="https://www.youtube.com/watch?v=3lPJ4sNiJxg"
          colorClass="bg-green-800"
        />
        <Testimonial
          name="web Developer"
          text="Hi, this is a very good tutorial about nodes and postgresql and docker-compose. Not so much good material out there."
          url="https://www.youtube.com/watch?v=Fa-oNdqOOYg#"
          colorClass="bg-red-500"
        />
        <Testimonial
          name="Hee Cha"
          text="Thank you!!!!"
          url="https://www.youtube.com/watch?v=3lPJ4sNiJxg"
          colorClass="bg-green-800"
        />
        <Testimonial
          name="Inayat Cassambai"
          text="Your teaching method - second to none."
          url="https://www.youtube.com/watch?v=UGVsDCMQf1A"
          colorClass="bg-yellow-600"
        />
        <Testimonial
          name="n4vyblueyes"
          text="I appreciate the walk through with the diagram."
          url="https://www.youtube.com/watch?v=bvQah0k5-eA"
          colorClass="bg-yellow-900"
        />
        <Testimonial
          name="cualkieras"
          text="Hi mate, thanks for this video."
          url="https://www.youtube.com/watch?v=URaGI2AvKoQ"
          colorClass="bg-gray-600"
        />
      </div>
    </>
  );
};

export default Testimonials;
