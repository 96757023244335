import React from "react";

const TechCard = (props) => {
  const tags = props.tags.map((tag, index) => (
    <span key={index} className="text-blue-500 font-medium mr-2">
      #{tag}
    </span>
  ));
  return (
    <div className="shadow-2xl p-8 mb-2 border-blue-500 rounded-xl border-dashed border-2">
      <h4 className="text-xl font-semibold">{props.heading}</h4>
      <div className="flex flex-wrap mb-2">{tags}</div>
      {props.children}
    </div>
  );
};

export default TechCard;
