import React from "react";

const Testimonial = (props) => {
  const firstNameInitial = props.name.charAt(0);

  return (
    <a href={props.url}>
      <div className="flex rounded-lg shadow-lg p-4 mb-8 mr-8 max-w-xl">
        <div
          className={`${props.colorClass} w-12 h-12 rounded-full  text-white flex-shrink-0 flex items-center justify-center`}
        >
          <span className="font-bold uppercase">{firstNameInitial}</span>
        </div>
        <div className="pl-4">
          <p className="text-lg font-semibold">{props.name}</p>
          <p className="text-gray-500">{props.text}</p>
        </div>
      </div>
    </a>
  );
};

export default Testimonial;
