import React from "react";

const PortfolioProjectButtons = (props) => {
  const buttonClasses = `mr-4 w-full inline-flex items-center justify-center px-2 py-1 shadow-sm font-medium border rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto`;
  return (
    <div className="mt-4 md:mt-2">
      <div className="flex">
        {props.demoUrl && (
          <a
            href={props.demoUrl}
            className={`${buttonClasses} text-white bg-blue-600 hover:bg-blue-700 border-transparent font-semibold`}
            target="_blank"
            rel="noreferrer"
          >
            Live Demo
          </a>
        )}

        <a
          href={props.codeUrl}
          className={`${buttonClasses} text-blue-600 bg-white border-blue-600 hover:border-blue-700 hover:text-blue-700`}
          target="_blank"
          rel="noreferrer"
        >
          Code
        </a>
      </div>
    </div>
  );
};

export default PortfolioProjectButtons;
